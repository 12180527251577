/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { firstMenu, secondMenu } from "../../data/sidebar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState("Home");
  const isSidebarCollapsed = useSelector((state: RootState) => state.toggle.isSidebarCollapsed);
  const handleActiveClass = (label: string) => {
    setActiveMenu(label);
  };
  return (
    <div className={`${isSidebarCollapsed ? '-translate-x-full' : 'translate-x-0 pr-2'} lg:block hidden h-full bg-white transition z-[1] fixed left-0 top-0 pt-[5.3rem]`}>
      <ul className="flex flex-col pb-3 px-2">
        {firstMenu.map((item, id) => (
          <li key={id}>
            <a
              href="#"
              onClick={() => handleActiveClass(item.label)}
              className={`
              ${
                activeMenu === item.label
                  ? "font-[500] bg-light-7"
                  : "font-[300]"
              }
                h-[40px]
                text-sm 
                w-[219px] 
                cursor-pointer 
                transition 
                flex 
                items-center 
                gap-3 
                px-5 
                py-2 
                rounded-[12px] 
                hover:bg-light-3
              `}
            >
              <span>
                <img
                  src={activeMenu === item.label ? item.activeIcon : item.icon}
                  alt="icon"
                  width={22}
                  height={15.5}
                />
              </span>
              <span className="font-inter text-dark-2">{item.label}</span>
            </a>
          </li>
        ))}
      </ul>
      <ul className="px-2 text-sm flex flex-col border-t-[2px] pt-3 border-light-5 gap-1">
        {secondMenu.map((item, id) => (
          <li key={id}>
            <a
              href="#"
              onClick={() => handleActiveClass(item.label)}
              className={`
                ${
                  activeMenu === item.label
                    ? "font-semibold bg-light-3"
                    : "font-[300]"
                }
                h-[40px]
                text-sm 
                w-[219px] 
                cursor-pointer 
                transition 
                flex 
                items-center 
                gap-3 
                px-5 
                py-2 
                rounded-sm 
                hover:bg-light-3
              `}
            >
              <span>
                <img
                  src={activeMenu === item.label ? item.activeIcon : item.icon}
                  alt="icon"
                  width={22}
                  height={15.5}
                />
              </span>
              <span className="font-inter">{item.label}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
