import React from "react";
import settings from "../../assets/icons/settings.svg";
import language from "../../assets/icons/language.svg";
import darkTheme from "../../assets/icons/dark-theme.svg";
import legalInfo from "../../assets/icons/legal info.svg";
import signIn from "../../assets/icons/login.svg";
import playlist from "../../assets/icons/playlists.svg";
import subscription from "../../assets/icons/subscriptions.svg";
import history from "../../assets/icons/history.svg";
import studio from "../../assets/icons/studio.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useDispatch } from "react-redux";
import {
  loginEditor,
  logoutEditor,
  logoutUser
} from "../../store/slices/loginSlice";

const UserMenuModal = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state: RootState) => state.login.userLoggedIn);
  const editorLogin = useSelector(
    (state: RootState) => state.login.editorLoggedIn
  );

  const handleLogout = () => {
    dispatch(logoutEditor());
    dispatch(logoutUser());
  };
  const handleEditorLogin = () => {
    dispatch(loginEditor());
  };
  return (
    <div
      className={`
        lg:hidden
        block z-[1] 
        h-[100vh] 
        font-inter 
        overflow-auto 
        bg-white 
        w-full 
        pb-40 
        left-0 
        right-0 
        fixed
        top-0
        ${editorLogin || userLogin ? "pt-[4.5rem]" : "pt-20"}
      `}
    >
      {editorLogin && (
        <div className="flex gap-2 px-5 items-center font-inter mb-5">
          <div className="bg-[#facc15] px-[1.5rem] py-[1.2rem] text-white rounded-full">
            M
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-stone-1 text-[18px]">
              Maria Llompart
            </div>
            <div className="flex items-center">
              <span className="text-stone-1 text-md font-medium">
                @mariallompart
              </span>
              <span className="text-stone-1 pb-1 text-md font-medium">
                &nbsp;.&nbsp;
              </span>
              <span className="text-blue-1 text-md font-medium">
                mariallompart.tv
              </span>
            </div>
            <div>5 Subs</div>
          </div>
        </div>
      )}
      {userLogin && (
        <div className="flex gap-2 px-5 pt-3 items-center font-inter mb-5">
          <div className="bg-[#facc15] px-[1.5rem] py-[1.2rem] text-white rounded-full">
            M
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-stone-1 text-[18px]">
              Maria Llompart
            </div>
            <div className="flex items-center">
              <span className="text-stone-1 text-md font-medium">
                @mariallompart
              </span>
            </div>
          </div>
        </div>
      )}
      <ul className="text-[22px] flex flex-col gap-2">
        {editorLogin && (
          <li className="flex gap-3 px-5 items-center border-b-gray-500">
            <span>
              <img src={studio} alt="studio" width={25} />
            </span>
            <span>Studio</span>
          </li>
        )}

        <li className="flex gap-3 px-5 items-center pb-2 border-b border-b-gray-500">
          <span>
            <img src={settings} alt="setting" width={25} />
          </span>
          <span>Settings</span>
        </li>
        <li className="flex px-5 gap-3 items-center pt-2">
          <span>
            <img src={playlist} alt="playlist" width={25} />
          </span>
          <span>Playlists</span>
        </li>
        <li className="flex px-5 gap-3 items-center">
          <span>
            <img src={subscription} alt="subscription" width={25} />
          </span>
          <span>Subscriptions</span>
        </li>
        <li className="flex px-5 gap-3 items-center pb-3 border-b border-b-gray-500">
          <span>
            <img src={history} alt="history" width={25} />
          </span>
          <span>History</span>
        </li>
        <li className="flex px-5 gap-3 items-center pt-2 justify-between">
          <div className="flex gap-3">
            <span>
              <img src={language} alt="language" width={25} />
            </span>
            <span>Language</span>
          </div>
          <div className="text-lg text-slate-700">English</div>
        </li>
        <li className="flex gap-3 px-5 items-center pb-3 border-b border-b-gray-500">
          <span>
            <img src={darkTheme} alt="darkTheme" width={25} />
          </span>
          <span>Dark theme</span>
          <span></span>
        </li>
        <li className="flex gap-3 px-5 items-center py-2 border-b border-b-gray-500">
          <span>
            <img src={legalInfo} alt="legalInfo" width={25} />
          </span>
          <span>Legal information</span>
        </li>
        <li className="flex gap-3 px-5 py-2 items-center">
          <span>
            <img src={signIn} alt="signIn" width={25} />
          </span>
          {userLogin || editorLogin ? (
            <span onClick={handleLogout}>Logout</span>
          ) : (
            <span onClick={handleEditorLogin}>Sign in</span>
          )}
        </li>
      </ul>
    </div>
  );
};

export default UserMenuModal;
