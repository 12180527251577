import { configureStore } from "@reduxjs/toolkit";
import toggleSlice  from "./slices/collapsibleSlice";
import loginSlice from "./slices/loginSlice";
import modalSlice from "./slices/userModalSlice";

export const store = configureStore({
    reducer: {
        toggle: toggleSlice,
        login: loginSlice,
        modal: modalSlice
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch