import skiing from "../../assets/images/video-cover/skiing.jpg";
import forest from "../../assets/images/video-cover/forest-camping-c.jpg";
import castle from "../../assets/images/video-cover/castle.jpg";
import country from "../../assets/images/video-cover/country.jpg";

export const allVideoData = [
  {
    src: country,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "EL Pufo de la peste negra que ni siquiera paso x espana",
    views: "1.3k",
    uploadTime: "1 week ago",
    videoDuration: "01:47:54"
  },
  {
    src: castle,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la democracia wa distraccion y cimunismo light",
    views: "15.3k",
    uploadTime: "2 days ago",
    videoDuration: "09:13"
  },
  {
    src: forest,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title:
      "letica y felipe no duermen juntos por esto: exclusiva jaime penafiel",
    views: "156.36k",
    uploadTime: "6 week ago",
    videoDuration: "00:54"
  },
  {
    src: skiing,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "EL Pufo de la peste negra que ni siquiera paso x espana",
    views: "21.5k",
    uploadTime: "3 weeks ago",
    videoDuration: "01:54"
  },
  {
    src: forest,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la democracia wa distraccion y cimunismo light",
    views: "784.8k",
    uploadTime: "2 week ago",
    videoDuration: "01:01:44"
  },
  {
    src: castle,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title:
      "letica y felipe no duermen juntos por esto: exclusiva jaime penafiel",
    views: "51.6k",
    uploadTime: "5 weeks ago",
    videoDuration: "01:47"
  },
  {
    src: skiing,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "EL Pufo de la peste negra que ni siquiera paso x espana",
    views: "172.3k",
    uploadTime: "4 week ago",
    videoDuration: "12:00"
  },
  {
    src: country,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la democracia wa distraccion y cimunismo light",
    views: "1.2m",
    uploadTime: "1 year ago",
    videoDuration: "07:24"
  },
  {
    src: castle,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la vida es un juego (muy serio pero de broma)",
    views: "361.3k",
    uploadTime: "3 week ago",
    videoDuration: "27:54"
  }
];

export const recommendedVideo = [
  {
    src: country,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "EL Pufo de la peste negra que ni siquiera paso x espana",
    views: "1.3k",
    uploadTime: "1 week ago",
    videoDuration: "01:47:54"
  },
  {
    src: castle,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la democracia wa distraccion y cimunismo light",
    views: "15.3k",
    uploadTime: "2 days ago",
    videoDuration: "09:13"
  },
  {
    src: forest,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title:
      "letica y felipe no duermen juntos por esto: exclusiva jaime penafiel",
    views: "156.36k",
    uploadTime: "6 week ago",
    videoDuration: "00:54"
  },
  {
    src: skiing,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "EL Pufo de la peste negra que ni siquiera paso x espana",
    views: "21.5k",
    uploadTime: "3 weeks ago",
    videoDuration: "01:54"
  },
  {
    src: forest,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la democracia wa distraccion y cimunismo light",
    views: "784.8k",
    uploadTime: "2 week ago",
    videoDuration: "01:01:44"
  },
  {
    src: castle,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title:
      "letica y felipe no duermen juntos por esto: exclusiva jaime penafiel",
    views: "51.6k",
    uploadTime: "5 weeks ago",
    videoDuration: "01:47"
  },
  {
    src: skiing,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "EL Pufo de la peste negra que ni siquiera paso x espana",
    views: "172.3k",
    uploadTime: "4 week ago",
    videoDuration: "12:00"
  },
  {
    src: country,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la democracia wa distraccion y cimunismo light",
    views: "1.2m",
    uploadTime: "1 year ago",
    videoDuration: "07:24"
  },
  {
    src: castle,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la vida es un juego (muy serio pero de broma)",
    views: "361.3k",
    uploadTime: "3 week ago",
    videoDuration: "27:54"
  },
  {
    src: country,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "EL Pufo de la peste negra que ni siquiera paso x espana",
    views: "1.3k",
    uploadTime: "1 week ago",
    videoDuration: "01:47:54"
  },
  {
    src: castle,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la democracia wa distraccion y cimunismo light",
    views: "15.3k",
    uploadTime: "2 days ago",
    videoDuration: "09:13"
  },
  {
    src: forest,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title:
      "letica y felipe no duermen juntos por esto: exclusiva jaime penafiel",
    views: "156.36k",
    uploadTime: "6 week ago",
    videoDuration: "00:54"
  },
  {
    src: skiing,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "EL Pufo de la peste negra que ni siquiera paso x espana",
    views: "21.5k",
    uploadTime: "3 weeks ago",
    videoDuration: "01:54"
  },
  {
    src: forest,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la democracia wa distraccion y cimunismo light",
    views: "784.8k",
    uploadTime: "2 week ago",
    videoDuration: "01:01:44"
  },
  {
    src: castle,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title:
      "letica y felipe no duermen juntos por esto: exclusiva jaime penafiel",
    views: "51.6k",
    uploadTime: "5 weeks ago",
    videoDuration: "01:47"
  },
  {
    src: skiing,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "EL Pufo de la peste negra que ni siquiera paso x espana",
    views: "172.3k",
    uploadTime: "4 week ago",
    videoDuration: "12:00"
  },
  {
    src: country,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la democracia wa distraccion y cimunismo light",
    views: "1.2m",
    uploadTime: "1 year ago",
    videoDuration: "07:24"
  },
  {
    src: castle,
    alt: "cover",
    channelName: "Colin Rivas Show",
    title: "la vida es un juego (muy serio pero de broma)",
    views: "361.3k",
    uploadTime: "3 week ago",
    videoDuration: "27:54"
  }
]