import React, { useState } from "react";
import menuIcon from "../../assets/icons/menu.svg";
import logo from "../../assets/images/upcastLogo.png";
import Container from "../../component/ui/Container";
import userMenu from "../../assets/icons/user.svg";
import search from "../../assets/icons/search.svg";
import addContent from "../../assets/icons/add-content.svg";
import notification from "../../assets/icons/notification.svg";
import Sidebar from "../sidebar/Sidebar";
import UserModal from "../../component/modals/UserModal";
import { useDispatch } from "react-redux";
import { toggleCollapsibleSidebar } from "../../store/slices/collapsibleSlice";
import { useNavigate } from "react-router";
import {
  loginEditor,
  logoutEditor,
  loginUser,
  logoutUser
} from "../../store/slices/loginSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { onClose, onOpen } from "../../store/slices/userModalSlice";

const Header = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorLogin = useSelector(
    (state: RootState) => state.login.editorLoggedIn
  );
  const userLogin = useSelector((state: RootState) => state.login.userLoggedIn);
  const showModal = useSelector((state: RootState) => state.modal.isOpen);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
    dispatch(toggleCollapsibleSidebar());
  };

  const handleNavigate = () => {
    navigate("/");
  };

  const menuHandler = () => {
    console.log("clicked", showModal);
    if (showModal) {
      dispatch(onClose());
    } else {
      dispatch(onOpen());
    }
  };

  const handleEditorLogin = () => {
    dispatch(loginEditor());
  };
  const handleUserLogin = () => {
    dispatch(loginUser());
  };
  const handleEditorLogout = () => {
    dispatch(logoutEditor());
    dispatch(onClose());
  };
  const handleUserLogout = () => {
    dispatch(logoutUser());
    dispatch(onClose());
  };
  return (
    <div className="relative h-full w-full  py-[16.5px]">
      <Container>
        <div className="fixed top-0 p-5 left-0 bg-white right-0 z-[2] flex items-center justify-between">
          <div className="flex justify-start gap-4">
            <div className="lg:block hidden">
              <img
                src={menuIcon}
                alt="svg"
                className="cursor-pointer"
                onClick={toggleSidebar}
              />
            </div>
            <div onClick={handleNavigate} className="cursor-pointer">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="flex items-center justify-end gap-4">
            {editorLogin || userLogin ? (
              <>
                <button type="button" className="block lg:hidden font-inter">
                  <img src={search} alt="search" width={25} height={31.5} />
                </button>
                {editorLogin && (
                  <div className="hidden lg:block">
                    <img src={addContent} alt="add" width={25} />
                  </div>
                )}
                <div>
                  <img src={notification} alt="add" width={25} />
                </div>
                <div
                  className="bg-[#a855f7] md:block hidden cursor-pointer user-menu-icon text-white text-lg px-[12px] py-[5px] rounded-full"
                  onClick={menuHandler}
                >
                  C
                </div>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="
                    lg:block
                    hidden
                    px-5 
                    py-2 
                    rounded-sm 
                    bg-light-1
                    font-semibold
                    tracking-wide
                    text-dark-1
                    text-sm 
                    hover:bg-light-2
                    transition
                  "
                  onClick={handleEditorLogin}
                >
                  Login
                </button>
                <button type="button" className="block lg:hidden font-inter">
                  <img src={search} alt="search" width={25} height={31.5} />
                </button>
                <button
                  type="button"
                  className="
                    px-5 
                    tracking-wide
                    py-2 
                    rounded-sm 
                    bg-primary-1 
                    hover:bg-primary-2 
                    text-sm 
                    font-semibold 
                    text-white
                    transition
                    font-inter
                  "
                  onClick={() => dispatch(loginUser())}
                >
                  Sign up
                </button>
                <div
                  className="cursor-pointer lg:block hidden user-menu-icon"
                  onClick={menuHandler}
                >
                  <img src={userMenu} alt="menu" height={16.5} width={15.5} />
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
      <div className="lg:block hidden fixed z-[3] top-[15px] left-1/2 -translate-x-1/2">
        <div
          className={`
            flex 
            rounded-sm
            justify-between 
            border-light-1
            border-[2px]
            items-center 
            lg:w-[610px]
            md:w-[500px]
            text-dark-1
            h-[45px]
            text-xs
              focus-within:border-primary-3
              focus-within:border-[2px]
          `}
        >
          <input
            type="text"
            placeholder="Search"
            className={`
              rounded-l-sm 
              px-2 
              font-sm 
              focus:outline-none
              w-full 
              py-[0.41rem]
            `}
          />
          <button
            type="button"
            className="bg-light-1 py-[11px] px-5
            rounded-r-[8px] font-[11px]"
          >
            <img src={search} alt="search" width={22} height={30} />
          </button>
        </div>
      </div>
      <Sidebar />
      {showModal && (
        <UserModal
          editorLogin={editorLogin}
          userLogin={userLogin}
          handleUserLogin={handleUserLogin}
          handleEditorLogout={handleEditorLogout}
          handleUserLogout={handleUserLogout}
        />
      )}
    </div>
  );
};

export default Header;
