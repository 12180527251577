import React, { useEffect } from "react";
import VideoCover from "../../component/ui/VideoCover";
import Avatar from "../../component/ui/Avatar";
import style from "../../assets/css/Homepage.module.css";
import { allVideoData } from "../../data/videos";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useDispatch } from "react-redux";
import {
  collapseSidebar,
  openSidebar
} from "../../store/slices/collapsibleSlice";
import { onClose } from "../../store/slices/userModalSlice";

const Homepage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSidebarCollapsed = useSelector(
    (state: RootState) => state.toggle.isSidebarCollapsed
  );
  const handleNavigation = (id: number) => {
    dispatch(collapseSidebar());
    dispatch(onClose())
    navigate(`/video/${id + 1}`);
  };
  useEffect(() => {
    dispatch(openSidebar());
  }, [dispatch]);

  return (
    <div className="my-[3.4rem]">
      <div
        className={`max-w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:px-5 gap-5 transition-all duration-300 ${
          isSidebarCollapsed
            ? "translate-x-0 ml-0 px-5"
            : "lg:ml-[15rem] translate-x-0"
        }`}
      >
        {allVideoData.map((item, id) => (
          <div
            key={id}
            className="cursor-pointer"
            onClick={() => handleNavigation(id)}
          >
            <div
              className={`
                relative 
                shadow-md 
                transition 
                max-w-full 
                rounded-3xl 
                h-[250px]
                ${style.playButtonContainer}
              `}
            >
              <VideoCover srcUrl={item.src} alt={item.alt} />
              <div className="bg-black text-white absolute right-2 text-sm px-1 rounded-[4px] bottom-2">
                {item.videoDuration}
              </div>
              {/* <div
                className={`${style.playButton} rounded-3xl absolute top-0 left-0 right-0 h-[300px] max-w-full transition`}
              ></div> */}
            </div>
            <div className="">
              <h1 className="hidden lg:block text-lg font-inter text-dark-2 pt-2 font-medium uppercase">
                {item.title}
              </h1>
              <div className="flex items-center lg:justify-start justify-start gap-1 lg:gap-0">
                <div>
                  <Avatar />
                </div>
                <div className="flex flex-col">
                  <h1 className="block lg:hidden font-inter text-lg text-dark-2 pt-2 font-medium uppercase">
                    {item.title}
                  </h1>
                  <div className="flex lg:flex-col gap-1 lg:gap-0 lg:items-start items-center lg:px-1">
                    <p className="text-xs font-inter text-slate-500 font-medium uppercase">
                      {item.channelName}
                    </p>
                    <p className="text-xs font-inter text-slate-500 font-[300] flex items-center justify-start">
                      <span>{item.views}</span>&nbsp;
                      <span className="pb-1"> . </span>&nbsp;
                      <span>{item.uploadTime}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Homepage;
