import React, { useState } from "react";
import { menubar } from "../../../data/sidebar";
import UserMenuModal from "../../../component/modals/UserMenuModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const Menubar = () => {
  const [activeMenu, setActiveMenu] = useState("home");
  const [show, setShow] = useState(false);

  const editorLogin = useSelector(
    (state: RootState) => state.login.editorLoggedIn
  );
  const userLogin = useSelector((state: RootState) => state.login.userLoggedIn);

  const handleActiveClass = (label: string) => {
    setActiveMenu(label);
  };
  const closeMenu = (label: string) => {
    if(label === "home") {
      setShow(false)
    }
  };
  const openMenu = (label: string) => {
    if (label === "user") setShow(!show);
  };
  return (
    <>
      <div className="block lg:hidden fixed bg-white z-[2] -bottom-2 left-0 right-0 w-full">
        <div
          className="
            grid 
            grid-cols-5
            py-5
            border-t
            border-light-1
          "
        >
          {menubar.map((item, id) => (
            <div
              key={id}
              className="m-auto"
              onClick={() => {
                handleActiveClass(item.label);
                openMenu(item.label);
                closeMenu(item.label);
              }}
            >
              {id === menubar.length - 1 && (userLogin || editorLogin) ? (
                <div className=" border-[1px] border-black rounded-full p-[2px]">
                  <div className="bg-[#a855f7] cursor-pointer user-menu-icon text-white text-lg w-[2rem] h-[2rem] py-[0.4rem] text-center rounded-full">
                    C
                  </div>
                </div>
              ) : (
                // Render default image
                <img
                  src={activeMenu === item.label ? item.activeIcon : item.icon}
                  alt={item.label}
                  width={35}
                />
              )}
            </div>
          ))}
          {/* {menubar.map((item, id) => (
            <div
              key={id}
              className="m-auto"
              onClick={() => {
                handleActiveClass(item.label);
                openMenu(item.label);
              }}
            >
              <img
                src={activeMenu === item.label ? item.activeIcon : item.icon}
                alt={item.label}
                width={35}
              />
            </div>
          ))} */}
        </div>
      </div>
      {show && <UserMenuModal />}
    </>
  );
};

export default Menubar;
