import React from 'react';

const Avatar = () => {
  return (
    <div className='bg-light-2 w-[2rem] h-[2rem] text-center pt-[0.25rem] align-middle rounded-full'>
       C {/* <img src={src} alt="avatar" className='border max-w-[1rem] max-h-[1rem] md:w-[3rem] md:h-[1.8rem] lg:w-[2rem] lg:h-[2rem] rounded-full'/> */}
    </div>
  )
}

export default Avatar