import { createSlice } from "@reduxjs/toolkit";

interface CollapseState {
    isSidebarCollapsed: boolean;
}

const initialState: CollapseState = {
    isSidebarCollapsed: false,
}

export const toggleSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        toggleCollapsibleSidebar : (state) => {
            state.isSidebarCollapsed = !state.isSidebarCollapsed
        },
        collapseSidebar: (state) => {
            state.isSidebarCollapsed = true
        },
        openSidebar: (state) => {
            state.isSidebarCollapsed = false
        }
    }
})

export const { toggleCollapsibleSidebar, collapseSidebar, openSidebar } = toggleSlice.actions;
export default toggleSlice.reducer