import home from "../../assets/icons/home.svg";
import explore from "../../assets/icons/explore.svg";
import trending from "../../assets/icons/trending.svg";
import about from "../../assets/icons/about.svg";
import playlists from "../../assets/icons/playlists.svg";
import subscriptions from "../../assets/icons/subscriptions.svg";
import history from "../../assets/icons/history.svg";
import activeHome from "../../assets/icons/hovered-icons/home-hover.svg";
import activeExplore from "../../assets/icons/hovered-icons/explore-hover.svg";
import activeTrending from "../../assets/icons/hovered-icons/trending-hover.svg";
import activeAbout from "../../assets/icons/hovered-icons/about-hover.svg";
import activePlaylists from "../../assets/icons/hovered-icons/playlists-hover.svg";
import activeSubscription from "../../assets/icons/hovered-icons/subscriptions-hover.svg";
import user from '../../assets/icons/user.svg';

export const firstMenu = [
  {
    label: "Home",
    icon: home,
    activeIcon: activeHome
  },
  {
    label: "Explore",
    icon: explore,
    activeIcon: activeExplore
  },
  {
    label: "Trending",
    icon: trending,
    activeIcon: activeTrending
  },
  {
    label: "About",
    icon: about,
    activeIcon: activeAbout
  }
];
export const secondMenu = [
  {
    label: "Playlists",
    icon: playlists,
    activeIcon: activePlaylists
  },
  {
    label: "Subscriptions",
    icon: subscriptions,
    activeIcon: activeSubscription
  },
  {
    label: "History",
    icon: history,
    activeIcon: history
  }
];

export const menubar = [
  {
    icon: home,
    activeIcon: activeHome,
    label: 'home'
  },
  {
    icon: explore,
    activeIcon: activeExplore,
    label: 'explore'
  },
  {
    icon: trending,
    activeIcon: activeTrending,
    label: 'trending'
  },
  {
    icon: about,
    activeIcon: activeAbout,
    label: 'about'
  },
  {
    icon: user,
    activeIcon: user,
    label: 'user'
  }
];
