import React from "react";
import viewIcon from "../../assets/icons/view.svg";
import { recommendedVideo } from "../../data/videos";
import Avatar from "./Avatar";
import { useNavigate } from "react-router";

const RecommendedVideo = () => {
  const navigate = useNavigate();
  const handleNavigate = (id: number) => {
    navigate(`/video/${id+2}`)
  }
  return (
    <div>
      {recommendedVideo.map((item, id) => (
        <div key={id} onClick={()=> handleNavigate(id)} className="lg:grid grid-cols-5 gap-2 pb-2 cursor-pointer lg:leading-4">
          <div className="relative col-span-2">
            <img
              src={item.src}
              alt={item.alt}
              width="100%"
              className="lg:rounded-lg object-cover h-[250px] md:h-[350px] lg:h-[110px]"
            />
            <div className="absolute right-2 bottom-2 px-1 rounded-[5px] text-sm bg-black text-white">
              {item.videoDuration}
            </div>
          </div>
          <div className="col-span-3 pt-1 lg:pt-0 px-2 lg:px-0">
            <div className="flex gap-1 items-center">
              <div className="block lg:hidden">
                <Avatar />
              </div>
              <div className="">
                <h1 className="line-clamp-2 uppercase font-semibold text-dark-2 text-lg lg:text-[14px]">
                  {item.title}
                </h1>
                <div className="flex lg:pt-1 lg:flex-col">
                  <p className="text-slate-500 font-[400] text-sm lg:text-[14px]">
                    {item.channelName}
                  </p>
                  <div className="flex items-center gap-1 leading-3">
                    <img
                      src={viewIcon}
                      alt="views"
                      width={21}
                      className="hidden lg:block"
                    />
                    <p className="text-sm lg:text-[12px] text-light-8 font-[400] flex lg:items-center justify-start">
                      <span>{item.views}</span>&nbsp;
                      <span className="pb-1">.</span>&nbsp;
                      <span>{item.uploadTime}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecommendedVideo;
