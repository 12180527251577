import { createBrowserRouter } from "react-router-dom";
import Layout from '../layout/Layout';
import Homepage from "../pages/homepage/Homepage";
import Video from "../pages/videopage/[video]";

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Homepage />
      },
      {
        path: '/video/:id',
        element: <Video />
      }
    ]
  },
]);