import { createSlice } from "@reduxjs/toolkit"; 

interface ModalState {
    isOpen: boolean;
}

const initialState: ModalState = {
    isOpen: false,
}

export const modalSlice = createSlice({
    name: "userModal",
    initialState,
    reducers: {
       toggleModal: (state) => {
        state.isOpen = !state.isOpen
       },
       onOpen: (state) => {
        state.isOpen = true
       },
       onClose: (state) => {
        state.isOpen = false
       }
    }
})

export const { toggleModal, onClose, onOpen } = modalSlice.actions;
export default modalSlice.reducer