import { createSlice } from "@reduxjs/toolkit";

interface LoginSliceState {
    editorLoggedIn: boolean;
    userLoggedIn: boolean;
}

const initialState: LoginSliceState = {
    editorLoggedIn: false, 
    userLoggedIn: false
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginEditor: (state) => {
            state.editorLoggedIn = true
        },
        loginUser: (state) => {
            state.userLoggedIn = true
        },
        logoutEditor: (state) => {
            state.editorLoggedIn = false
        },
        logoutUser: (state) => {
            state.userLoggedIn = false
        },
    }
})

export const { loginEditor, loginUser, logoutEditor, logoutUser } = loginSlice.actions;

export default loginSlice.reducer;