import React, { useEffect } from "react";
import VideoContainer from "../../component/ui/VideoContainer";
import RecommendedVideo from "../../component/ui/RecommendedVideo";
import { useDispatch } from "react-redux";
import { collapseSidebar } from "../../store/slices/collapsibleSlice";

const Video = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(collapseSidebar());
  }, [dispatch])
  return (
    <div className="my-[3.5rem]">
      <div className="lg:px-5">
        <div className="lg:grid lg:grid-cols-4 gap-5">
          <div className="col-span-3">
            <VideoContainer />
          </div>
          <div className="py-5 lg:py-0">
            <RecommendedVideo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
