import React from 'react';

interface VideoCoverProps {
  srcUrl: string;
  alt: string;
}
const VideoCover: React.FC<VideoCoverProps> = ({ srcUrl, alt }) => {
  return (
    <div>
      <img src={srcUrl} alt={alt} className='transition lg:rounded-2xl md:rounded-2xl w-full h-[250px] object-cover' />
    </div>
  )
}

export default VideoCover