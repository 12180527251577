/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import ReactPlayer from "react-player";
import video from "../../assets/placeholder/video/placeholder.mp4";
import like from "../../assets/icons/like.svg";
import dislike from "../../assets/icons/like.svg";
import share from "../../assets/icons/share.svg";
import addPlaylist from "../../assets/icons/addPlaylist.svg";
import userIcon from "../../assets/icons/user.svg";
import Avatar from "./Avatar";
import { TbDotsVertical } from "react-icons/tb";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";

const VideoContainer = () => {
  const [showText, setShowText] = useState(false);
  return (
    <div className="">
      <ReactPlayer
        className="react-player"
        url={video}
        width="100%"
        playing={true}
        // light={true}
        height="60%"
        controls={true}
        style={{ borderRadius: "1.25rem" }}
      />
      <div className="px-2 pt-2 lg:px-0">
        <h1
          className="
            text-dark-2
            lg:text-[25px]
            font-bold
            font-sans
            uppercase
          "
        >
          Que sabia espinosa de los monteros y lo echaron de vox
        </h1>
        <div className="lg:flex justify-between">
          <p className="text-sm text-slate-500 font-[400] flex lg:items-center justify-start">
            <span>12.5k views</span>&nbsp;
            <span className="pb-1">.</span>&nbsp;
            <span>1 week ago</span>
          </p>
          <div className="flex justify-between items-center gap-4">
            <div className="flex gap-5">
              <div className="cursor-pointer">
                <img src={like} alt="icon" width={20} />
              </div>
              <div className="cursor-pointer">
                <img src={dislike} alt="icon" width={20} />
              </div>
            </div>
            <div className="flex items-center cursor-pointer gap-2">
              <img src={share} alt="icon" width={20} />
              <span className="font-semibold text-lg">Share</span>
            </div>
            <div className="flex items-center cursor-pointer gap-2">
              <img src={addPlaylist} alt="icon" width={20} />
              <span className="font-semibold text-lg">Save</span>
            </div>
            <div className="flex items-center cursor-pointer gap-2">
              <span className="font-semibold text-lg"><TbDotsVertical /></span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex py-5 px-2 lg:px-0 items-center justify-between lg:justify-start gap-2">
        <div className="flex items-center gap-2">
          <Avatar />
          <h3 className="uppercase text-slate-500 font-[300] text-sm">
            colin rivas show
          </h3>
        </div>
        <button className="border-[2px] bg-white text-dark-1 text-sm py-1 font-bold border-dark-1 rounded-[8px] px-2">
          Subscribe
        </button>
      </div>
      <div className="px-2 lg:px-0">
        <span className="bg-light-1 uppercase text-sm rounded-[8px] mr-2 font-[700] px-2 py-1 text-blue-1">
          Colinrivas
        </span>
        <span className="bg-light-1 text-sm uppercase rounded-[8px] mr-2 font-[709] px-2 py-1 text-blue-1">
          trump
        </span>
        <span className="bg-light-1 text-sm uppercase rounded-[8px] mr-2 font-[700] px-2 py-1 text-blue-1">
          vox
        </span>
      </div>
      <div className="bg-light-4 transition-all mx-2 lg:mx-0 rounded-lg my-8 hover:bg-light-5 leading-8 p-3 lg:p-5">
        <a
          href="#"
          className="text-blue-1 lg:hidden block font-inter text-md text-medium"
        >
          https://www.colinrivas.show
        </a>
        <p className="text-dark-1 font-inter font-[300] leading-4 text-sm">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
          magni fugit autem natus eos praesentium quos eius adipisci totam. Quae
          culpa mollitia aperiam autem, recusandae ea ab voluptatibus sit
          voluptate!
        </p>
        <a
          href="#"
          className="text-blue-1 hidden lg:block font-inter text-md text-medium"
        >
          https://www.colinrivas.show
        </a>
        <p
          className="text-dark-1 flex items-center gap-1 cursor-pointer text-[13px] font-bold font-verdana"
          onClick={() => setShowText(!showText)}
        >
          <span>Show more</span>
          <span><IoIosArrowDown /></span>
        </p>
        <p
          className={`text-dark-1 font-inter font-[300] leading-4 text-sm transition ${
            showText ? "flex flex-col" : "hidden"
          }`}
        >
          <span>
            Pide mis LIBROS aquí https://www.amazon.com/-/e/B07SJJ3R5Q
          </span>
          <span>Propinas en BTC 🏻 15ndjeW6NuAHFhHcSNYXVsyZPNkWFyrZKw</span>
          <span>
            propinas ETH 🏻 ethereum
            0x488746dc3ba1e897d1fbaf2f2bdbe5ef1516e...Pide mis LIBROS aquí
            https://www.amazon.com/-/e/B07SJJ3R5QPropinas en BTC 🏻
            15ndjeW6NuAHFhHcSNYXVsyZPNkWFyrZKw propinas ETH 🏻 ethereum
            0x488746dc3ba1e897d1fbaf2f2bdbe5ef1516e...Pide mis LIBROS aquí
            https://www.amazon.com/-/e/B07SJJ3R5QPropinas en BTC 🏻
            15ndjeW6NuAHFhHcSNYXVsyZPNkWFyrZKw propinas ETH 🏻 ethereum
            0x488746dc3ba1e897d1fbaf2f2bdbe5ef1516e...
          </span>
        </p>
      </div>
      <div className="flex items-center gap-5 px-2 lg:px-0">
        <p className="text-dark-2 font-lg font-bold font-inter">0 Comments</p>
        <p className="flex items-center gap-1 cursor-pointer font-inter font-medium text-[13px] text-slate-600 uppercase">
          <span>sort by</span>
          <span><IoMdArrowDropdown /></span>
        </p>
      </div>
      <div className="my-8 px-2 lg:px-0 flex gap-5 items-center">
        <div className="bg-light-1 rounded-full p-2">
          <img src={userIcon} alt="icon" className="w-[20px]" />
        </div>
        <div className="border-b-[2px] p-1 border-light-6 w-full font-inter text-[13px] font-medium">
          Add comment
        </div>
      </div>
    </div>
  );
};

export default VideoContainer;
