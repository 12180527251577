import React, { useEffect } from "react";
import settings from "../../assets/icons/settings.svg";
import language from "../../assets/icons/language.svg";
import darkTheme from "../../assets/icons/dark-theme.svg";
import legalInfo from "../../assets/icons/legal info.svg";
import signIn from "../../assets/icons/login.svg";
import studio from "../../assets/icons/studio.svg";
import { onClose } from "../../store/slices/userModalSlice";
import { useDispatch } from "react-redux";
import { MdOutlineToggleOff } from "react-icons/md";
import { SlArrowRight } from "react-icons/sl";

interface UserModalProps {
  editorLogin: boolean;
  userLogin: boolean;
  handleEditorLogout: () => void;
  handleUserLogin: () => void;
  handleUserLogout: () => void;
}
const UserModal: React.FC<UserModalProps> = ({
  editorLogin,
  userLogin,
  handleEditorLogout,
  handleUserLogout,
  handleUserLogin
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modal = document.querySelector(".user-modal");
      const icon = document.querySelector(".user-menu-icon");
      if (
        modal &&
        !modal.contains(event.target as Node) &&
        icon &&
        !icon.contains(event.target as Node)
      ) {
        dispatch(onClose());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  return (
    <div className="z-[3] hidden user-modal md:block fixed right-5 top-[3.8rem] shadow-lg text-sm bg-white font-medium rounded-lg w-[350px] border border-light-1">
      <ul className="font-sm flex flex-col">
        {editorLogin && (
          <li className="flex cursor-pointer gap-3 px-5 py-2 transition items-center hover:bg-light-1">
            <span>
              <img src={studio} alt="studio" width={25} />
            </span>
            <span>Studio</span>
          </li>
        )}
        <li className="flex gap-3 cursor-pointer items-center px-5 py-2 transition hover:bg-light-1 border-b border-b-gray-500">
          <span>
            <img src={settings} alt="setting" width={25} />
          </span>
          <span>Settings</span>
        </li>
        <li className="flex gap-3 px-5 py-2 hover:bg-light-1 transition cursor-pointer items-center">
          <span>
            <img src={language} alt="language" width={25} />
          </span>
          <span>Language</span>
        </li>
        <li className="flex gap-3 justify-between items-center px-5 py-2 transition hover:bg-light-1 cursor-pointer pt-2 pb-3 border-b border-b-gray-500">
          <div className="flex items-center gap-3">
            <span>
              <img src={darkTheme} alt="darkTheme" width={25} />
            </span>
            <span>Dark theme</span>
          </div>
          <div className="font-xl">
            <MdOutlineToggleOff style={{ fontSize: "40px", color: "" }} />
          </div>
        </li>
        <li className="flex justify-between gap-3 px-5 py-2 transition hover:bg-light-1 items-center cursor-pointer border-b border-b-gray-500">
          <div className="flex items-center gap-3">
            <span>
              <img src={legalInfo} alt="legalInfo" width={25} />
            </span>
            <span>Legal information</span>
          </div>
          <div>
            <SlArrowRight />
          </div>
        </li>
        <li className="flex gap-3 px-5 py-2 transition hover:bg-light-1 cursor-pointer items-center">
          <span>
            <img src={signIn} alt="signIn" width={25} />
          </span>
          {editorLogin || userLogin ? (
            <span
              onClick={() => {
                handleUserLogout();
                handleEditorLogout();
              }}
            >
              Logout
            </span>
          ) : (
            <span onClick={handleUserLogin}>Sign in</span>
          )}
        </li>
      </ul>
    </div>
  );
};

export default UserModal;
